<template>
    <div id="BookDetails">
        <section id="book-main" class="w70 w85-mb">
            <div class="book-group">
                <div class="book-info-group serif w70-mb">
                    <aside>
                        <img
                            v-lazy="books[index].imgUrl"
                            :alt="books[index].title + 'image'"
                        />
                        <a
                            :href="books[index].linkUrl"
                            target="_blank"
                            class="f14 f14-mb bold underline"
                            >Amazon.co.jpで詳細を見る</a
                        >
                    </aside>
                    <main>
                        <h2 class="f29 f22-mb">{{ books[index].title }}</h2>
                        <p
                            class="f14 f12-mb"
                            v-html="wrap(books[index].info.author)"
                        ></p>
                        <ul class="f14 f12-mb">
                            <li
                                v-for="(subitem, subindex) in books[index].info
                                    .table"
                                :key="'book-' + index + '-info-' + subindex"
                            >
                                <span class="head">{{ subitem.head }}</span>
                                <span class="body">{{ subitem.body }}</span>
                            </li>
                        </ul>
                        <router-link :to="{ name: 'Join' }" class="f12 f12-mb"
                            >お問い合わせフォームへ</router-link
                        >
                    </main>
                </div>
                <ul class="book-menu-group serif f16 f14-mb">
                    <li
                        v-for="(subitem, subindex) in books[index].articles"
                        :key="'book-' + index + '-menu-' + subindex"
                        @click="toggleMenu(index, subindex)"
                    >
                        <i class="icon-right-right"></i
                        >{{ ' ' + subitem.title }}
                    </li>
                </ul>
                <div
                    class="book-article-group"
                    :id="'book-' + index + '-article'"
                >
                    <article
                        v-for="(subitem, subindex) in books[index].articles"
                        :key="'book-' + index + '-article-' + subindex"
                        :id="'book-' + index + '-article-' + subindex"
                    >
                        <h3 class="serif f16 f14-mb">
                            【{{ subitem.title }}】
                        </h3>
                        <div
                            v-if="subitem.type === 'article'"
                            class="article-article f14 f12-mb"
                        >
                            <p
                                v-for="(
                                    subSubitem, subSubindex
                                ) in subitem.article.split('\n')"
                                :key="
                                    'book-' + index + '-article-' + subSubindex
                                "
                            >
                                {{ subSubitem }}
                            </p>
                        </div>
                        <ul
                            v-else-if="subitem.type === 'contents'"
                            class="article-content serif w80-mb"
                        >
                            <li
                                v-for="(
                                    subSubitem, subSubindex
                                ) in subitem.contents"
                                :key="
                                    'book-' + index + '-contents-' + subSubindex
                                "
                            >
                                <aside>
                                    <h4 class="f14 f12-mb bold">
                                        {{ subSubitem.left }}
                                    </h4>
                                </aside>
                                <main>
                                    <h4
                                        v-html="wrap(subSubitem.right.head)"
                                        class="f14 f12-mb bold"
                                    ></h4>
                                    <p
                                        v-html="wrap(subSubitem.right.body)"
                                        class="f12 f12-mb"
                                    ></p>
                                </main>
                            </li>
                        </ul>
                    </article>
                </div>
                <div class="book-button-group serif">
                    <router-link :to="{ name: 'Join' }" class="f12 f12-mb"
                        >お問い合わせフォームへ</router-link
                    >
                </div>
            </div>
        </section>
    </div>
</template>
<script>
import { computed } from 'vue'
import { useRoute } from 'vue-router'
import { reactive } from '@vue/reactivity'
import { addBackgroundImage, wrap, scrollToElement } from '@/utils/common.js'
import getBookData from '@/data/getBookData.js'
export default {
    name: 'BookDetails',
    setup() {
        const index = computed(() => useRoute().params.index)
        const { books } = getBookData()
        let stretchedBook = reactive([])
        function toggle(index) {
            let el = document.getElementById('book-' + index + '-article')
            if (stretchedBook.includes(index)) {
                stretchedBook.splice(stretchedBook.indexOf(index), 1)
                el.style.height = '21em'
                window.scrollTo({
                    top: el.parentNode.offsetTop - 78,
                    left: 0,
                    behavior: 'smooth',
                })
            } else {
                stretchedBook.push(index)
                el.style.height = el.scrollHeight + 'px'
            }
        }
        function toggleMenu(index, subindex) {
            if (!stretchedBook.includes(index)) toggle(index)
            scrollToElement('#book-' + index + '-article-' + subindex)
        }
        return {
            addBackgroundImage,
            wrap,
            scrollToElement,
            books,
            stretchedBook,
            toggle,
            toggleMenu,
            index,
        }
    },
}
</script>
<style lang="scss" scoped>
.book-intro {
    text-align: center;
    a {
        color: $black;
    }
}
.book-group {
    padding: 3em 0;
    border-bottom: 1px solid $grayE;
    &:last-child {
        border-bottom: none;
    }
}
.book-info-group {
    aside {
        img {
            box-shadow: $shadow2;
        }
        a {
            display: block;
            text-align: center;
            line-height: 3em;
        }
    }
    main {
        flex: 1;
        h2 {
            border-bottom: 2px solid $grayE;
        }
        p,
        ul {
            margin: 1em 0;
            line-height: 1.5em;
        }
        ul {
            span {
                display: inline-block;
            }
            .head {
                width: 6em;
            }
        }

        a {
            @include solidButtonStyle();
            width: 200px;
        }
    }
}
.book-menu-group {
    li {
        width: fit-content;
        cursor: pointer;
    }
    i {
        display: inline-block;
        transform: scale(0.7);
    }
}
.book-article-group {
    transition: 1s ease-in-out;
    article {
        margin: 2em 0;
    }
    h3 {
        line-height: 3em;
    }
    .article-article {
        p {
            text-indent: 2em;
        }
    }
    .article-content {
        li {
            display: flex;
            margin: 1em 0;
            box-sizing: border-box;
        }
        aside {
            flex: 0 0 4em;
        }
    }
}
footer {
    text-align: center;
    cursor: pointer;
    color: $gray6;
    margin: 1em 0;
    i {
        display: block;
        font-size: 12px;
        line-height: 1em;
    }
    &.expanded {
        i {
            display: block;
            transform: rotate(180deg);
        }
    }
}
.book-button-group {
    display: flex;
    justify-content: center;
    a {
        @include solidButtonStyle();
        width: 200px;
    }
}

@media screen and (min-width: 768px) {
    .book-info-group {
        display: flex;
        align-items: center;
        aside {
            margin-right: 3em;
            img {
                display: block;
                width: 100%;
                max-width: 200px;
            }
        }
    }
    .book-menu-group {
        margin: 2em 0;
    }
}
@media screen and(max-width:767px) {
    .book-intro {
        margin-top: 50px;
    }
    .book-info-group {
        aside {
            text-align: center;
            img {
                max-width: 60%;
            }
        }
        main {
            margin: 2em 1em 5em;
            a {
                margin: 30px auto;
            }
        }
    }
}
</style>
