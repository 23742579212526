export default function () {
    const books = [
        {
            title: '神の理性',
            imgUrl: require('@/assets/book/book-1.jpg'),
            linkUrl:
                'https://www.amazon.co.jp/神の理性-ジナ-サーミナラ/dp/4884813405',
            info: {
                author: 'ジナ・サーミナラ（著）\n \
                    大自然界（翻訳）',
                table: [
                    {
                        head: '出版社',
                        body: 'たま出版',
                    },
                    {
                        head: '発売日',
                        body: '1994/6/1',
                    },
                    {
                        head: '言語',
                        body: '日本語',
                    },
                    {
                        head: 'ページ数',
                        body: '464ページ',
                    },
                    {
                        head: 'ISBN',
                        body: '4-88481-340-5',
                    },
                    {
                        head: '価格',
                        body: '2,000円',
                    },
                ],
            },
            articles: [
                {
                    title: '序文より抜粋',
                    type: 'article',
                    article:
                        '先ず、あなたは他の惑星に住む宇宙人で、地球人類を調査する為にUFOの様な宇宙船に乗って地球にやって来たと仮定してみよう。地球人類の文明や文化を調べる為に色々な装置をもって来ているので、あなたは地球人の色々な言葉を上手に話すことが出来、地球人に怪しまれる事なしに調査が出来ると仮定してみよう。\n \
                        少し調査を始めてあなたが一番びっくりする事は地球の人々が信仰している様々な宗教宗派の建築物の違いであろう。あなたがびっくりする理由はこうである。\n \
                        地球人達は科学的事柄については一致している。地球人達の道路、自動車や飛行機、軍隊の組織や核兵器、電話、テレビ、ラジオなどは殆ど基本的に同じである。地球人達の数学・科学・物理学・科学技術などの原理は基本的にみな一致しており、世界的に共通な概念があるのだ。にも拘らず、生きる事に対して科学と同様に大切な信仰と宗教に関しては全くばらばらなのである。\n \
                        地球人達の信仰や宗教には彼等の科学的知識とは反対に不一致、対立、争い、敵意などがあるのだ。しかも奇妙な事には、宗教の教えの基本はみな同胞愛を説いているのである。だから、あなたが不可解になるのも無理はない。この事を考えれば考える程不可解になったあなたは、ついにもう少し地球に留まって詳しい調査をさせてもらうよう、期間の延長の許可を宇宙本部に要請するであろう。そして地球時間で一年間の調査延長の許可がおりたとしよう。さあ、あなたはこの問題を専門に調べ始めるのである。･･･',
                },
                {
                    title: '目次',
                    type: 'contents',
                    contents: [
                        {
                            left: '第1章',
                            right: {
                                head: '「非全体化の原則」と宗教',
                                body: '誰も全てを知り尽くしてはいない\n \
                                    宗教は真理そのものではない！\n \
                                    唯一絶対を説く宗教こそ独善的である！',
                            },
                        },
                        {
                            left: '第2章',
                            right: {
                                head: '過程の原則と宗教（その一）',
                                body: 'この世の全ては変化の過程にある\n \
                                    古代の経典・聖典に囚われている者は過去に縛られ変化の理に逆らう\n \
                                    永遠普遍なる神は素晴らしき変化に富んだ世界を営んでいる\n \
                                    各聖典・経典は大自然界の真理の一部を説いているが間違いの部分もある',
                            },
                        },
                        {
                            left: '第3章',
                            right: {
                                head: '過程の原則と宗教(その二)',
                                body: '感覚的に感知している事が本当の姿ではない\n \
                                    悩み苦しみ痛みなどの感覚は自己の観念の産物であり、体験・経験によって自己の霊的観念として収穫される',
                            },
                        },
                        {
                            left: '第4章',
                            right: {
                                head: '個有性と宗教',
                                body: 'それぞれの存在は宇宙でたった一つの個有性(宇宙には二つと同じものがない)\n \
                                    大自然界の大宇宙は個有性によって成り立っている！\n \
                                    個有性を持つ各人に一つの信仰を押しつける権利は誰にもない\n \
                                    神は新しい時代を始める為にこの混乱の時代を滅ぼすのか？',
                            },
                        },
                        {
                            left: '第5章',
                            right: {
                                head: '抽象性',
                                body: '宗教宗派で説く真理とは、大自然界の事実を認識した抽象的観念である\n \
                                    大自然界の事実は同じでも、人間の認識の仕方が異なる為に各宗教宗派が出来上がる！\n \
                                    目に見えないものに対する主観的解釈と宗教宗派の分裂',
                            },
                        },
                        {
                            left: '第6章',
                            right: {
                                head: 'キリスト教の聖書における原始的愚劣さと矛盾',
                                body: '聖書は全てが神の言葉か？？人によって描かれた聖書の愚劣さの部分\n \
                                    旧約聖書に書かれた近親相姦などの醜悪な部分\n \
                                    聖書を絶対化して信じる危険性と盲信\n \
                                    イエスの説いた真の意味とは？',
                            },
                        },
                        {
                            left: '第7章',
                            right: {
                                head: '抽象的観念と肉体的死の新しい解釈',
                                body: '大自然界における生命エネルギーの永遠・普遍性の悟り\n \
                                    肉体的死の恐怖を地球人類は如何に克服するか？\n \
                                    肉体的死とは単に生命エネルギーの周波数の変化か？\n \
                                    霊的観念を改善せずに、肉体的処罰の死刑を行う事は価値があるのか？',
                            },
                        },
                        {
                            left: '第8章',
                            right: {
                                head: '抽象と宗教の起源',
                                body: '真理として人間が知覚・認識した大自然界の事実や摂理\n \
                                    偉大なる始祖達と一般人の知覚・認識の違い\n \
                                    教祖の悟りと説かれた教えの部分。言葉による伝達の問題点',
                            },
                        },
                        {
                            left: '第9章',
                            right: {
                                head: '宗教成立の過程・エーズィーイズム教成立の例',
                                body: 'この本における宗教団体の実験的創造例\n \
                                    教祖達にとって宗教宗派は無かった\n \
                                    宗教宗派は後世の人間が作り出した団体組織\n \
                                    一つの宗教の分裂と宗派の出来方の例\n \
                                    人間の行う宗教宗派は形髄化し、そして人間的に堕落する',
                            },
                        },
                        {
                            left: '第10章',
                            right: {
                                head: 'コミュニケーションの困難な問題',
                                body: '如何なる宗教宗派の記述もその伝達の過程で必然的に誤解や偏見が生じる\n \
                                    宗教宗派の記述よりも大自然界の事実を基に認識・解釈すべきである',
                            },
                        },
                        {
                            left: '第11章',
                            right: {
                                head: '宗教における採択・編集の問題(その一)',
                                body: '偉大なる始祖達の生涯や教えは全てが記録されてはいない\n \
                                    記録は部分的である。その部分の記録を集めて人間が聖典・教典とした\n \
                                    聖書・教典は後世の人間が自分達の観念によって編集したものである',
                            },
                        },
                        {
                            left: '第12章',
                            right: {
                                head: '宗教における採択・編集の問題(その二)',
                                body: 'イエスの失われた記録。イエスはエッセネ派集団の修行者であり、菜食主義者・輪廻転生主義者であった！\n \
                                    聖書には記載されて無いイエスの説いた言葉',
                            },
                        },
                        {
                            left: '第13章',
                            right: {
                                head: '人為的に作られた聖書(その採択・編集の問題点)',
                                body: '大自然界の生命現象を継続する為の神聖なる性に対する聖書の中の偏見と冒濱\n \
                                    この面での聖書の功罪はマイナスの方が大きい\n \
                                    権力者・支配者など人間の偏見や都合で改ざんされた聖書の実態',
                            },
                        },
                        {
                            left: '第14章',
                            right: {
                                head: '段階度合いの原則',
                                body: '人々はそれぞれ理解の段階が能力によって異なる\n \
                                    大自然界の真理を理解するには知性・理性・感性が必要である\n \
                                    IQなどの知能テストだけでは総合的な能力判断は出来ない\n \
                                    理性的判断能力・感性的愛情能力などのテストの開発も必要である',
                            },
                        },
                        {
                            left: '第15章',
                            right: {
                                head: '大自然界の段階的体系と天使・神々の存在',
                                body: '神々や天使とは如何なる存在か？\n \
                                    大自然界における全ての営みは神の知性によるものなのか？\n \
                                    宇宙や銀河の他の惑星にも救世主は存在するのか？',
                            },
                        },
                        {
                            left: '第16章',
                            right: {
                                head: '段階と奇跡とインスピレーション',
                                body: '生命エネルギー・霊能力そして超常現象の関係\n \
                                    高い次元の知的生命体が大自然界の真理をもって（奇跡をもって）人類を導く\n \
                                    神の啓示・インスピレーション・心霊現象など',
                            },
                        },
                        {
                            left: '第17章',
                            right: {
                                head: '意味 一',
                                body: '聖書の言葉の意味は解釈が違った為にキリスト教の宗派が出来て対立する結果となった\n \
                                    写真は偶像でいけないという聖書の言葉の意味の愚かしい解釈の例\n \
                                    聖典・教典の意味を解釈が異なって宗教宗派となり対立・抗争となった',
                            },
                        },
                        {
                            left: '第18章',
                            right: {
                                head: '意味 二',
                                body: '宗教宗派が人類に与えたマイナスの観念、罪汚れ・業生・因縁など\n \
                                    聖書の言葉の意味とイエス再臨の意味\n \
                                    大自然界の「循環の理法」とゴールデン・ルール',
                            },
                        },
                        {
                            left: '第19章',
                            right: {
                                head: '意味 三',
                                body: '聖書に書かれたイエスの言葉の真の意味とは？\n \
                                    普遍的意味と限定された意味の違い\n \
                                    聖書の言葉を一般化する場合の危険性',
                            },
                        },
                        {
                            left: '第20章',
                            right: {
                                head: '意味 四',
                                body: '人間の都合や偏見で誤解・曲解される言葉の意味と解釈\n \
                                    聖書の改ざんと取捨選択',
                            },
                        },
                        {
                            left: '第21章',
                            right: {
                                head: '意味 五',
                                body: '伝達の方法による誤解・曲解\n \
                                    女性に対する人類の偏見が出来た聖書の原因\n \
                                    女性蔑視の聖書の原因',
                            },
                        },
                        {
                            left: '第22章',
                            right: {
                                head: '意味 六',
                                body: '聖書・聖典に見るUFOの記述',
                            },
                        },
                        {
                            left: '第23章',
                            right: {
                                head: '翻訳',
                                body: '最後の審判の意味の危険な解釈と誤解によるマイナス観念\n \
                                    神は新しい時代を始める為に過去の時代を終わりにする\n \
                                    大自然界の生命に人間・動物・植物の差はないのだ！',
                            },
                        },
                        {
                            left: '第24章',
                            right: {
                                head: '総合調和の為の道具としての一般意味論',
                                body: '大自然界の真理による地球の宗教宗派の調和と統一\n \
                                    宇宙に通用する永遠・普遍性への霊的観念\n \
                                    独善的・狂信的宗教宗派からの人類の脱皮',
                            },
                        },
                        {
                            left: '第25章',
                            right: {
                                head: '水瓶座・理性的時代の徳質',
                                body: '生命的財産である美徳\n \
                                    物質界の執着・金・財産・権力からの超越\n \
                                    真の意志と勇気とは生命愛の発展である',
                            },
                        },
                        {
                            left: '第26章',
                            right: {
                                head: '理性的時代の必要不可欠なる論理',
                                body: '生命愛の科学と技術\n \
                                    人類が行っている悪魔的動物実験と運命的マイナス原因\n \
                                    結果は必ず人類の運命として返ってくる！',
                            },
                        },
                        {
                            left: '第27章',
                            right: {
                                head: '新しき理性の時代の観念と生き方',
                                body: '新しき理性の時代と神性地球\n \
                                    宇宙・銀河における生命的愛の神惑星\n \
                                    大自然界の中の生命の家族と愛の生存！\n \
                                    訳者あとがき',
                            },
                        },
                    ],
                },
            ],
        },
        {
            title: '大自然の大いなる生命の大親',
            imgUrl: require('@/assets/book/book-2.jpg'),
            linkUrl: 'https://amzn.asia/d/2qtoil3',
            info: {
                author: '大自然界（著）',
                table: [
                    {
                        head: '出版社',
                        body: '講談社エディトリアル',
                    },
                    {
                        head: '発売日',
                        body: '2024/4/19',
                    },
                    {
                        head: '言語',
                        body: '日本語',
                    },
                    {
                        head: 'ページ数',
                        body: '260ページ',
                    },
                    {
                        head: 'ISBN',
                        body: '4866771437',
                    },
                    {
                        head: '価格',
                        body: '1,500円（税別）',
                    },
                ],
            },
            articles: [
                {
                    title: '「はじめに」より抜粋',
                    type: 'article',
                    article:
                        '夜空に輝く星々を眺めていて大宇宙の悠久を感じた時、壮大な山々や果てしなく広がる風景を見た時、感動とともに不思議な気持ちになったことはありませんか？\n \
                        「自分の存在は何なのだろう」\n \
                        「なぜ生まれてきたのだろう」\n \
                        「宇宙やこの地球の素晴らしい自然はどうやって創られたのだろう」\n \
                        「“私”のこの気持ちはどこからやってきたのだろう」\n \
                        「生命の根源は何なのだろう」\n \
                        ふだんは考えない思いが色々と湧いてくるのではないでしょうか？\n \
                        \n \
                        しかし、湧いてくる疑問の明確な答えは地球上のどこを探してもありません。世界のあらゆるところで、そして人類の歴史を通しても多くの哲学者や宗教家が「自己の存在」や「より良く生きること」を色々と考えてきました。哲学者や宗教家が難しく、一生懸命に考えても未だに答えは出ていません。残念なことに人間の考えでは答えを出すことができないのです。生命も気持ち（魂）も宇宙も大自然に働いている真理法則も人間が創ったものではないから、人間にはわからないのです。\n \
                        \n \
                        この本に書かれていることは、一人の人間が考えたような一つの思想ではありません。私たちの生命を含め全生物に生命を与えて下さり、宇宙を創り、すべての大自然の営みをして下さっておられる『生命の大親』よりお授け頂きました事実の教えです。\n \
                        \n \
                        地球は、今、存続の危機となるような大きな問題を抱えております。温暖化、宗教・宗派の争い、民族紛争、核兵器の問題。地球人の誰もがこの問題を早く解決しなくては地球が滅亡してしまうとわかっているでしょう。しかし地球人の今の意識では解決する方法が見つかりません。（･･･中略･･･）地球の危機だからこそ、全生物の「生命の大親」であられる『大親神大生命』が今、地球に現れてくださり、大自然の事実をお授けして下さっておられるのです。',
                },
                {
                    title: '目次',
                    type: 'contents',
                    contents: [
                        {
                            left: 'はじめに',
                            right: {
                                head: '',
                                body: '',
                            },
                        },
                        {
                            left: '第一章',
                            right: {
                                head: '『大親神大生命』 ～すべての「生命の大親」～',
                                body: '『大親神大生命』は大自然の大いなる生命のお力・お働きです\n \
                                   『大親神大生命』はすべての「生命の大親」です\n \
                                   &emsp;全生物は『大親神大生命』の「生命の家族」です\n \
                                   『大親神大生命』は大いなるエネルギー・生命力働そのものです\n \
                                   『大親神大生命』は大自然の大いなる御心・意志です\n \
                                   『大親神大生命』は永遠普遍なる親御存在です\n \
                                   『大親神大生命』はすべてに絶対必要不可欠な親御存在です\n \
                                   『大親神大生命』は「根源的全体・親の理」の親御存在です\n \
                                   『大親神大生命』はすべての創造主です\n \
                                   『大親神大生命』は大自然界のすべての営みをして下さっておられます\n \
                                   『大親神大生命』は知性・理性・感性の根源であり全能です\n \
                                   &emsp;全生物は『大親神大生命』の「生命の御子　生命霊」です\n \
                                   『大親神大生命』に親感謝して生きると人生も地球の運命も素晴らしくなります',
                            },
                        },
                        {
                            left: '第二章',
                            right: {
                                head: '「大親真体系　大自然界」\n \
                                    ～『大親神大生命』がお創り下さった世界～',
                                body: '「大親真体系　大自然界」\n \
                                   「親生命界」\n \
                                   &emsp;『大親神大生命』御自親の生命の世界が「親生命界」です\n \
                                   &emsp;『大親神大生命』より「生命」、「生命力働」を頂いています\n \
                                   &emsp;「生きる」とは生命活動の姿です\n \
                                   「親命霊界」\n \
                                   &emsp;『大親神大生命』御自親の大いなる意志・御心の世界が「親命霊界」です\n \
                                   &emsp;生命霊は「固有性」を持っています\n \
                                   &emsp;「生命霊」は輪廻転生して成長発展しています\n \
                                   &emsp;輪廻転生を通して様々な気持ちを収穫しています　～観念のフィルム～\n \
                                   &emsp;気持ちを変えれば運命・環境は変えられます\n \
                                   &emsp;「親心」は『大親神大生命』の大親心の一部を頂いたものです\n \
                                   &emsp;「輪廻転生」を繰り返して「親心」の「生命霊」になっていきます\n \
                                   「親物質現象界」\n \
                                   &emsp;『大親神大生命』御自親の物質体・御体の世界が「親物質現象界」です\n \
                                   &emsp;「生老病死」は素晴らしい味わいです',
                            },
                        },
                        {
                            left: '第三章',
                            right: {
                                head: '「大自然の真理法則」\n \
                                   ～大自然界を成り立たせている根本の原理～',
                                body: '&emsp;大自然の真理法則とは\n \
                                   &emsp;大自然の真理法則を総合的に理解して人生に活用する生き方が大切です\n \
                                   &emsp;『大親神大生命』は「十元の理法」をもって大自然のすべてを創造され、営みをなされておられます\n \
                                   &emsp;「自明の理」　～事実であることの証明～\n \
                                   存在の原理～私たちはなぜ存在するのか～\n \
                                   &emsp;1．役割機能の原則\n \
                                   &emsp;2．自然生成の原則\n \
                                   &emsp;3．自然淘汰の原則\n \
                                   &emsp;大自然からの警告を真剣に受け止める時です\n \
                                   有意味行為の原則　～何のために生きるのか～\n \
                                   &emsp;人生課題は「生命霊」の成長発展のためにあるのです\n \
                                   &emsp;『大親神大生命』にお受け取り頂ける「有意味行為」の生き方を実践しましょう\n \
                                   &emsp;「有意味行為の原則」をもって科学技術を使わせて頂くべきです\n \
                                   &emsp;その理で行えばその理が働きます\n \
                                   &emsp;実際に行なったことが「生命霊観念」の収穫になります',
                            },
                        },
                        {
                            left: '第四章',
                            right: {
                                head: '大自然界の十元の理法　～大自然を構成している根源的な真理法則～',
                                body: '十元の理法とは\n \
                                   &emsp;「十元の理法」と宗教の教義では大きな違いがあります\n \
                                   「十元の理法」\n \
                                   &emsp;1．能動変化の理法\n \
                                   &emsp;&emsp;「能動変化の理法」に沿えば素晴らしい人生へと変化します\n \
                                   &emsp;2．平均・調和・安定の理法\n \
                                   &emsp;&emsp;徳のバランスと運命・環境\n \
                                   &emsp;3．出発発展の理法\n \
                                   &emsp;4．循環・還元の理法\n \
                                   &emsp;5．強度・継続の理法\n \
                                   &emsp;6．種類分化の理法\n \
                                   &emsp;7．終止限界の理法\n \
                                   &emsp;8．作用総合の理法\n \
                                   &emsp;9．原因・結果の理法\n \
                                   &emsp;10．機会・場の理法',
                            },
                        },
                        {
                            left: '第五章',
                            right: {
                                head: '大自然界の真理法則に沿ってより良く生きる',
                                body: '&emsp;真理法則に沿った生き方を実際に行なってみる\n \
                                   『大親神大生命』の御親愛を感じて生きる\n \
                                   &emsp;幸福の本質\n \
                                   「生きる」目的は「生命霊」に素晴らしい収穫をすること\n \
                                   &emsp;悟りと我慢の違い\n \
                                   &emsp;主体性と責任転嫁\n \
                                   &emsp;自己縮小と自己拡大\n \
                                   &emsp;絶対真理念と相対信念\n \
                                   &emsp;真種・真材料の理　～地球の新しい生き方の種蒔き～',
                            },
                        },
                        {
                            left: '第六章',
                            right: {
                                head: 'おつなぎ徳積みの理\n \
                                   ～『大親神大生命』からお与え頂く永遠普遍の財産～',
                                body: '「徳」とは『大親神大生命』が認めて下さった生命財産です\n \
                                  「徳」とは永遠普遍なる生命財産です\n \
                                  「徳」とは「生命の御子」が生きるうえで絶対必要不可欠な財産です\n \
                                  &emsp;日々の「おつなぎ徳積み」\n \
                                  「おつなぎ徳積み」は能動的意志で実行させて頂きます\n \
                                  『大親神大生命』にお受け取り頂いてこそ「徳積み」になります\n \
                                  「徳積み」になる行いとは、他のプラスとなる真心の行いです\n \
                                  「徳人」と「不徳人」\n \
                                  &emsp;徳人の住む惑星へ　～地球の大いなる発展のために～\n \
                                  &emsp;食物連鎖の理　～他の役に立って「徳」を高めていく～\n \
                                  &emsp;気持ちと気持ちが繋がる「生命霊食物　親感謝の理」　～よく嚙んで食べることの素晴らしさ～',
                            },
                        },
                        {
                            left: 'あとがき',
                            right: {
                                head: '',
                                body: '',
                            },
                        },
                    ],
                },
            ],
        },
    ]

    return { books }
}
